export async function lotException(query) {
  let prefix = query.slice(0, 4);
  //link on logo done inside firebase/brands/prefix

  if (prefix == "0310") {
    //0310L20F479
    return ["origin", true];
  }

  if (prefix == "0208") {
    //AAFA
    //document.querySelector(".lot--AAFA-Logo").classList.add("show");
  }

  if (prefix == "0103") {
    if (
      query == "0103L20FN3253" ||
      query == "0103L20F3034" ||
      query == "0103L20FN1020" ||
      query == "0103L20FN1860" ||
      query == "0103L20FN2064" ||
      query == "0103L20FN2064"
    ) {
      return ["brandName", "ALLIED HOME"];
    }
    if (
      query == "01031020954" ||
      query == "0103L20F3034" ||
      query == "0103L20FN3253"
    ) {
      return ["brandName", " "];
    }

    if (query == "01031020954") {
      return ["brandName", "Weatherproof Vintage Home"];
    }
    if (query == "01030129150") {
      return ["brandName", "SIMMONS FOR COSTCO"];
    }
  }  
  if (prefix == "1601") {
    if (
      query == "1601L24FN0515" || 
      query == "1601L24FN0516"
    ) {
      return ["brandName", "LOLE"];
    }
  }

  if (prefix == "0205") {
    if (query == "0205L21F157" || query == "0205L20F1054") {
      return ["brandName", "PILGRIM SURF + SUPPLY"];
    }
    if (query == "0205L21FN1507") {
      return ["brandName", "B:MING by BEAMS"];
    }
  }

  if (prefix == "1308") {
    if (
      query == "1308L22FN665" ||
      query == "1308L22FN666" ||
      query == "1308L22FN1652" ||
      query == "1308L22FN590" ||
      query == "1308L22F7161"
    ) {
      return ["brandName", "MOUNTAIN WORKS", "1323", false];
    }
  }
  
  if (prefix == "1315") {
    if (
      query == "1315L23FN5222"  
    ) {
      return ["redirect", "?lot=1301L23FN5222"];
    }
    if (
      query == "1315L23FN4438"  
    ) {
      return ["redirect", "?lot=1301L23FN4438"];
    }
  }
  
  if (query == "L24FN0954") {
    // 1124
    //return ["display_lot", "L24FN0954"];
    return ["redirect", "?lot=1124L24FN0954"];
  }
  
  if (query == "15020417999") {    
    return ["redirect", "?lot=15020417999R"];
  }  
  
  if (prefix == "0115") {
    if (
      query == "01150321125" ||
      query == "01150218216" ||
      query == "0115YL170220" ||
      query == "0115L16FN2576" ||
      query == "0115L17FN0219" ||
      query == "0115YL170325" ||
      query == "0115YL170326" ||
      query == "0115YL170327" ||
      query == "0115L17FN0218" ||
      query == "0115YL170115" ||
      query == "0115YL170311" ||
      query == "0115L17F273" ||
      query == "0115L17FN0231" ||
      query == "01150319527" ||
      query == "01150122361"
    ) {
      return ["brandName", "ATOMIC"];
    }
  }  

  if (prefix == "0404") {
    //clean up zero based specs for this prefix
    //document.querySelector("body").classList.add("ddZero");
    //document.querySelector(".summary .lotSection-wrap").childNodes[4].classList.add('hide');
    //if (afdLot.statedFP == 0)
    //if(afdLot.landfowl == "0.0%")
    //if(afdLot.quill == "0.0%")
  }

  if (prefix == "1605") {
    if (query == "1605L20F1359" || query == "1605L20F1360") {
      return ["brandName", "PXG"];
    }
    if (
      query == "1605L19F2555" ||
      query == "1605L21F1608" ||
      query == "1605L20FN5089" ||
      query == "1605L22F7244" ||
      query == "1605L24F7101"
    ) {
      return ["brandName", "ADAM ET ROPE"];
    }
  }
  if (prefix == "0127") {
    if (query == "0127L22FN001" || query == "0127L22FN1288") {
      return ["brandName", "ALTON"];
    }
  }

  if (prefix == "0502") {
    if (query == "0502L22F020") {
      return ["redirect", "L22F020.html"];
    }
    if (query == "0502L21F2620") {
      return ["redirect", "L21F2620.html"];
    }
  }

  if (query == "0310L21F1378") {
    return ["brandName", "CJ Basquiat • Eddie Bauer"];
  }
    
  if (query == "2201L17F312") {
    return ["brandName", "Under Armour"];
  }

  if (query == "1809L23FN1603") {
    return ["brandName", "VARG"];
  }

  if (query == "1912L21FN366") {
    return ["brandName", "SLY"];
  }
  if (
    query == "0509L18FN2936" ||
    query == "0509L19FN3589" ||
    query == "0509L20FN2895" ||
    query == "0509L22FN2203" ||
    query == "0509L22FN3923" ||
    query == "0509L22FN4935"
  ) {
    return ["brandName", "SKOLMAR 32 AS"];
  }
  if (query == "0121L20FN1625") {
    return ["brandName", "AZUL"];
  }

  if (query == "1327L21F1199" || query == "1327L21F1197") {
    return ["brandName", "ALLIED FEATHER + DOWN"];
  }

  if (prefix == "1914") {
    if (query == "19141118411") {
      return ["brandName", " "];
    }

    if (query == "19140722536") {
      return ["redirect", "0722536.html"];
    }
  }
  
  if (query == "16160315375") {
    return ["redirect", "?lot=04040315375"];
  } 
  
  if (query == "L23F0429 L23F0235" || query == "L23F0429.L23F0235") {
    return ["redirect", "?lot=1107L23F0429-1"];
  }  
  if (query == "L23F0235 L23F0429" || query == "L23F0235.L23F0429") {
    return ["redirect", "?lot=1107L23F0235-1"];
  }        
  if (query == "L23F0235") {
    return ["redirect", "?lot=1107L23F0235"];
  }  
  if (query == "L23F0359") {
    return ["redirect", "?lot=1107L23F0359"];
  }   
  if (query == "L22F607") {
    return ["redirect", "?lot=1107L22F607"];
  }  
  if (query == "L23F0444") {
    return ["redirect", "?lot=1107L23F0444"];
  }
  if (query == "L23F7144") {
    return ["redirect", "?lot=1107L23F7144"];
  }
  if (query == "0722544") {
    return ["redirect", "?lot=11070722544"];
  }      
  
  if (prefix == "1107") {    
    if (query == "1107L23F0429") {
      return ["display_lot", "L23F0429"];
    }
    if (query == "1107L23F0429-1") {
      return ["display_lot", "L23F0429.L23F0235"];
    }
    if (query == "1107L23F0235") {
      return ["display_lot", "L23F0235"];
    }
    if (query == "1107L23F0235-1") {
      return ["display_lot", "L23F0235.L23F0429"];
    }    
    if (query == "1107L23F0359") {
      return ["display_lot", "L23F0359"];
    }
    if (query == "1107L22F607") {
      return ["display_lot", "L22F607"];
    }
    if (query == "1107L23F0444") {
      return ["display_lot", "L23F0444"];
    }
    if (query == "1107L23F7144") {
      return ["display_lot", "L23F7144"];
    }
    if (query == "11070722544") {
      return ["display_lot", "0722544"];
    }                          
  }

  if (query == "1806L19F2555") {
    return ["redirect", "?lot=1828L19F2555"];
  }
  if (query == "1914L19F2598") {
    return ["redirect", "salomon.html"];
  }
  
  if (prefix == "2118") {    
    if (query == "2118L0723754") {
      return ["brandName", "UNITED ARROWS & SONS"];
    }
  }
  
  if (prefix == "2101") {
    if (query == "2101L22F2480") {
      return ["redirect", "?lot=2101L22F2480-1A"];
    }
  }
  
  if (prefix == "2505") {
    if (query == "2505L0923776") {
      return ["redirect", "?lot=25050923776"];
    }
  }
  return prefix;
}

export async function ddException(x, y, z) {
  //fillPower
  let response = {};
  console.log(x + " | " + y + " | " + z);

  x == "/" || x == "0" ? (response.vfp = true) : (response.vfp = false);
  y == "0.0" ? (response.land_fowl = true) : (response.land_fowl = false);
  z == "0.0" ? (response.quill = true) : (response.quill = false);
  return response;
}
