import React from 'react';
//{ 'https://v1.trackmydown.com/'+ rptLink+".pdf" }

function contentEN(rptLink){  
  return(<><p>Down is an all-natural organic by-product and as such, it is impossible to achieve a lot with a true 100% pure down cluster without any trace of fiber, feathers, or other miscellaneous material. ALLIED prides itself on working closely with our partners to provide the best possible content for each particular use.</p><p>With ALLIED inside, you can feel confident that the product you are looking at has been developed with a level of precision and optimization unique to the industry. Working together with our partners, we can utilize different cluster sizes, contents and fill power to help create products that have better performance, resiliency, and feel for the entire life of the product.</p><br /><br /><a href={ '//cdn.trackmydown.com/'+ rptLink+".pdf" } target="_blank" rel="noopener noreferrer">&gt; Material Test Report</a><br /><br /></>)
}

function contentFR(rptLink){	
  return(<><p>Le duvet est un sous-produit organique entièrement naturel et, à ce titre, il est impossible d&#39;obtenir beaucoup avec une véritable grappe de duvet pur à 100 % sans aucune trace de fibre, de plumes ou d&#39;autres matières diverses. ALLIED est fier de travailler en étroite collaboration avec ses partenaires afin de fournir le meilleur contenu possible pour chaque utilisation particulière.</p><p>Depuis des décennies, le duvet a toujours été utilisé de manière assez générique. Avec ALLIED à l&#39;intérieur, vous pouvez être sûr que le produit que vous regardez a été développé avec un niveau de précision et d&#39;optimisation unique dans l&#39;industrie. En travaillant avec nos partenaires, nous pouvons utiliser différentes tailles de grappes, différents contenus et différentes puissances de remplissage pour aider à créer des produits qui ont de meilleures performances, une meilleure résilience et une meilleure sensation pendant toute la durée de vie du&nbsp;produit.</p><br /><br /><a href={ '//cdn.trackmydown.com/'+ rptLink+".pdf" }target="_blank" rel="noopener noreferrer">&gt; Rapport Sur Les Essais De Matériaux</a><br /><br /></>)
}

function SpecificContent(props){    
  //let rptLink = props.report;
  let rptLink = props.cdnLink;
  
  // if(rptLink.search('.pdf')){
  //   rptLink = rptLink.split(".")[0];
  // }
  
  switch(props.activeLang){
    case "fr":	
	return contentFR(rptLink)
	default:
	return contentEN(rptLink)
  }	  
}

const DownContent = (props) => {   
  // eslint-disable-line
  let cleanTitle, cleanDownCluster, cleanLandFowl, cleanDownFiber, cleanBrokenFeathers, cleanFeatherFiber, cleanQuill, cleanWaterFowl, cleanResidue;
  
  switch(props.activeLang){
    case "fr":	    	    
    cleanTitle			= "CONTENU";
	cleanDownCluster    = "PLUMULE";
	cleanLandFowl       = "LAND FOWL";
	cleanDownFiber		= "FIBRE";
	cleanBrokenFeathers = "PLUMES BRISÉES";
	cleanFeatherFiber   = "FIBRE DE PLOMB";
	cleanQuill			= "QUILL";
	cleanWaterFowl		= "WATERFOWL";
	cleanResidue		= "RESIDUES";
	break;
	
	default:
	cleanTitle 			= "CONTENT"; 
	cleanDownCluster    = "DOWN CLUSTER";
	cleanLandFowl       = "LAND FOWL";
	cleanDownFiber		= "DOWN FIBER";
	cleanBrokenFeathers = "BROKEN FEATHERS";
	cleanFeatherFiber   = "FEATHER FIBER";
	cleanQuill			= "QUILL";
	cleanWaterFowl		= "WATERFOWL";
	cleanResidue		= "RESIDUE";
	break;
  } 
  
  //lotException 
  let lfHide = false;
  let qHide = false;
    
  if(props.brand == "0404"){
	props.lf == '0.0' ? lfHide = true : lfHide = false
    props.q  == '0.0' ? qHide = true  : qHide = false
    
  }
  
  return (<div className="content-ac co hide">
  <input
    className="ac-input top"
    id="ac-5afd"
    name="ac-5afd"
    data-id="2400"
    data-slug="CONTENT"
    type="checkbox"
    onChange={ () => props.changeWord('content clicked') }
  />
  <label className="ac-label" htmlFor="ac-5afd">{ cleanTitle }</label>
  <article className="ac-text ac-5afd"><div className="single-post-interior" tabIndex="0">
    <table 
      data-lfhide = { lfHide }
      data-qhide = { qHide}
    >
    <tbody>
    <tr>
    <td>{ cleanDownCluster } : { props.dc }%</td>
    <td>{ cleanBrokenFeathers } : { props.bf }%</td>        
    </tr>
    <tr>
    <td>{ cleanDownFiber } : { props.df }%</td>
    <td>{ cleanResidue } : { props.r }%</td>
    </tr>
	<tr>
    <td>{ cleanFeatherFiber } : { props.ff }%</td>
    { !lfHide ? <td>{ cleanLandFowl } : { props.lf }%</td> : <td></td> }    
    </tr>
    <tr>
    <td>{ cleanWaterFowl } : { props.wf }%</td>
    { !qHide ? <td>{ cleanQuill } : { props.q }%</td> : <td></td> }
    </tr>
    </tbody>
    </table>
    <br />
    <SpecificContent
      activeLang = { props.activeLang }
      report 	 = { props.report }
      cdnLink 	 = { props.cdnLink }
	/>		     
        {/*
	    <p>
          Down is an all-natural organic by-product and as such, it is
          impossible to achieve a lot with a true 100% pure down cluster without
          any trace of fiber, feathers, or other miscellaneous material. ALLIED
          prides itself on working closely with our partners to provide the best
          possible content for each particular use.
        </p>
        <p>
          With ALLIED inside, you can feel confident that the product you are
          looking at has been developed with a level of precision and
          optimization unique to the industry. Working together with our
          partners, we can utilize different cluster sizes, contents and fill
          power to help create products that have better performance,
          resiliency, and feel for the entire life of the product.
        </p>
        <br /><br />
        <a
          href={ 'https://v1.trackmydown.com/'+ props.report }
          target="_blank"          
        >
          &gt; Material Test Report</a
        >
        */}
    </div></article>
  </div>
);
}

export default DownContent;